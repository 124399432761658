@-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    -webkit-animation: spinner 1.5s linear infinite;
            animation: spinner 1.5s linear infinite;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
* {
    margin: 0;
    padding: 0;
  }
  
  .App {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .userlist-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
  .userlist-container {
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px 20px;
    gap: 5px 20px;
  }
  .user-container {
    height: 100px;
    display: flex;
    border: 1px solid black;
  }
  .userDetail {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  button {
    margin-top: 10px;
    border: 2px solid #383636;
    color: #383636;
    background-color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    font-family: "Lato", sans-serif;
  }
  button:hover {
    background-color: #383636;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
  .error {
    color: red;
  }
